import { useTokenRefresh } from "./hooks/useTokenRefresh";
import { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { Route, Routes } from "react-router-dom";
import { refreshSessionId, getTokenResource, postUserToken } from "./services/message.service";
import { PageLoader } from "./components/page-loader";
import { AuthenticationGuard } from "./components/authentication-guard";
import { CallbackPage } from "./pages/callback-page";
import { HomePage } from "./pages/home-page";
import { NotFoundPage } from "./pages/not-found-page";
import { ProfilePage } from "./pages/profile-page";
import { LeasesDataPage } from "./pages/lease-data-page";
import { LeasesReviewPage } from "./pages/leases-review-page";
import { LeasesSearchPage } from "./pages/leases-search-page";
import { AboutUsPage } from "./pages/about-us-page";
import { TermsAndConditions } from "./pages/terms-20231206";
import { PrivacyPolicy } from "./pages/privacy-20240911";
//import { DataRoom } from "./pages/data-room";

export const App = () => {
  const { isLoading, isAuthenticated, user, getAccessTokenSilently } = useAuth0();
  useTokenRefresh(); // Enable token refresh

  useEffect(() => {
    const handlePostLogin = async () => {
      if (isAuthenticated && user) {
        try {
          const accessToken = await getAccessTokenSilently();

          // Step 1: Fetch CSRF token
          const { data: csrfToken, error: csrfError } = await getTokenResource(accessToken);
          if (csrfError) {
            console.error("App: Error fetching CSRF token:", csrfError);
            return;
          }
          console.log("App: CSRF Token successfully fetched:", csrfToken);

          // Step 2: Store CSRF token in session storage
          sessionStorage.setItem("csrf_token", csrfToken);

          // Log CSRF token from sessionStorage
          const storedCsrfToken = sessionStorage.getItem("csrf_token");
          if (!storedCsrfToken) {
            console.error("CSRF Token not found in sessionStorage!");
          }
          console.log("App: CSRF Token from sessionStorage:", storedCsrfToken);

          // Step 3: Post user data using CSRF token
          const { error: postError } = await postUserToken(accessToken, user.sub, storedCsrfToken);
          if (postError) {
            console.error("App: Error posting user token:", postError);
            return;
          }
          console.log("App: User data successfully posted.");

          // Step 4: Refresh session ID
          console.log("App: Calling refreshSessionId endpoint with user:", user);
          await refreshSessionId(accessToken, user);
        } catch (error) {
          console.error("App: Post-login processing failed:", error);
        }
      }
    };

    handlePostLogin();
  }, [isAuthenticated, user, getAccessTokenSilently]);

  if (isLoading) {
    return (
      <div className="page-layout">
        <PageLoader />
      </div>
    );
  }

  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/about" element={<AboutUsPage />} />
      <Route path="/terms" element={<TermsAndConditions />} />
      <Route path="/privacy" element={<PrivacyPolicy />} />
      <Route
        path="/review"
        element={<AuthenticationGuard component={LeasesReviewPage} />}
      />
      <Route
        path="/search"
        element={<AuthenticationGuard component={LeasesSearchPage} />}
      />
      <Route
        path="/data"
        element={<AuthenticationGuard component={LeasesDataPage} />}
      />
      <Route
        path="/profile"
        element={<AuthenticationGuard component={ProfilePage} />}
      />
      <Route path="/callback" element={<CallbackPage />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};