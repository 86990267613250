import React, { useRef, useEffect, useState } from 'react';
import { postLeasePDF, postExtractionFeedback, getPortfolios, getProperties, getTenantsNames, getLeasesByTenant, getAssets } from "../services/message.service";
import { useAuth0 } from "@auth0/auth0-react";
import * as pdfjsLib from 'pdfjs-dist';
import { Button, Spinner } from 'react-bootstrap';
import '../styles/components/modal.css';
import '../styles/components/table.css';
import '../styles/components/page-loader.css';
import { PageLoader } from '../components/page-loader';

pdfjsLib.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.min.js`;

function LeaseUploader() {
  const fileInputRef = useRef(null);
  const { getAccessTokenSilently, isAuthenticated, user } = useAuth0();
  //const [csrfToken, setCSRFToken] = useState(null);
  const [uploadKey, setUploadKey] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [step, setStep] = useState(0); // Tracks the current modal step
  const [portfolios, setPortfolios] = useState([]);
  const [selectedPortfolio, setSelectedPortfolio] = useState('');
  const [manualPortfolio, setManualPortfolio] = useState('');
  const [assets, setAssets] = useState([]);
  const [selectedAsset, setSelectedAsset] = useState('');
  const [manualAsset, setManualAsset] = useState('');
  const [properties, setProperties] = useState([]);
  const [selectedProperty, setSelectedProperty] = useState('');
  const [tenants, setTenants] = useState([]);
  const [selectedTenant, setSelectedTenant] = useState('');
  const [manualTenant, setManualTenant] = useState('');
  const [leases, setLeases] = useState([]);
  const [selectedLease, setSelectedLease] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [isCommercialUser, setIsCommercialUser] = useState(null); // Tracks user type
  
  const [timestampFile, setTimestampFile] = useState(""); // Make sure this state exists
  const [extractedData, setExtractedData] = useState(null);

  // New state to handle incorrect feedback
  const [showIncorrectMessage, setShowIncorrectMessage] = useState(false);


  // Fetch portfolios on load
  useEffect(() => {    
    //first way to get csrf token = Option A: Use a useEffect to load the CSRF token into local state
    // Pull from sessionStorage (assuming App.js already fetched & stored it)
    //const storedToken = sessionStorage.getItem("csrf_token");
    //if (storedToken) {
      //setCSRFToken(storedToken);
    //}
    // Ensure the user is authenticated and the user object is fully defined
    if (isAuthenticated && user?.email && user?.sub) {
      (async () => {
        try {
          const accessToken = await getAccessTokenSilently();
          const response = await getPortfolios(accessToken, user.email, user.sub);
          setPortfolios(response.data || []);  // Ensure response.data is an array
        } catch (error) {
          console.error('LeaseUploader - Error fetching portfolios:', error);
        }
      })();
    }
  }, [isAuthenticated, user?.email, user?.sub, getAccessTokenSilently]);
  


  // A function to handle user type selection
  const handleUserTypeSelection = (type) => {
    setIsCommercialUser(type);
    setShowModal(true);
    setStep(1); // Move to the next step after selection
  };

  // Handle portfolio change
  const handlePortfolioChange = async (event) => {
    const portfolio = event.target.value;
    setSelectedPortfolio(portfolio);
    setManualPortfolio('');  // Reset manual entry if a portfolio is selected
    setSelectedAsset('');
    setManualAsset('');
    setSelectedProperty('');  // Reset Properties, tenants, and leases when changing portfolio
    setSelectedTenant('');
    setManualTenant('');
    setSelectedLease('');
    setProperties([]);
    setTenants([]);
    setLeases([]);

    if (portfolio) {
      try {
        const accessToken = await getAccessTokenSilently();
        const response = await getAssets(accessToken, user.email, user.sub, portfolio);
        setAssets(response.data || []);
      } catch (error) {
        console.error('LeaseUploader - Error fetching Properties for portfolio:', error);
      }
    }
  };

  // Handle manual portfolio entry
  const handleManualPortfolioChange = (event) => {
    const portfolio = event.target.value;
    setManualPortfolio(portfolio);
    setSelectedPortfolio('');  // Reset selected portfolio if manually entering a portfolio
    setSelectedAsset('');
    setManualAsset('');
    setSelectedProperty('');  // Reset Properties, tenants, and leases when changing portfolio
    setSelectedTenant('');
    setManualTenant('');
    setSelectedLease('');
    setProperties([]);
    setTenants([]);
    setLeases([]);
  };

  // Handle property change
  const handleAssetChange = async (event) => {
    const asset = event.target.value;
    setSelectedAsset(asset);
    setManualAsset('');
    setSelectedProperty('');
    setSelectedTenant('');  // Reset tenants and leases when changing property
    setManualTenant('');
    setSelectedLease('');
    setTenants([]);
    setLeases([]);

    if (asset) {
      try {
        const accessToken = await getAccessTokenSilently();
        const portfolio = selectedPortfolio || manualPortfolio;  // Use either selected or manually entered portfolio
        const response = await getProperties(accessToken, user.email, user.sub, portfolio, asset);
        setProperties(response.data || []);
      } catch (error) {
        console.error('LeaseUploader - Error fetching Properties for portfolio:', error);
      }
      try {
        const accessToken = await getAccessTokenSilently();
        const response = await getTenantsNames(accessToken, user.email, user.sub);
        setTenants(response.data || []);
      } catch (error) {
        console.error('LeaseUploader - Error fetching tenants for portfolio:', error);
      }
    }
  };

  // Handle manual property entry
  const handleManualAssetChange = (event) => {
    const asset = event.target.value;
    setManualAsset(asset);
    setSelectedProperty('');  // Reset selected property if manually entering a property
    setSelectedTenant('');  // Reset tenants and leases when changing property
    setManualTenant('');
    setSelectedLease('');
    setTenants([]);
    setLeases([]);
  };

  // Handle property change
  const handlePropertyChange = async (event) => {
    const property = event.target.value;
    setSelectedProperty(property);
    setSelectedTenant('');  // Reset tenants and leases when changing property
    setManualTenant('');
    setSelectedLease('');
    setTenants([]);
    setLeases([]);

    if (property) {
      try {
        const accessToken = await getAccessTokenSilently();
        const response = await getTenantsNames(accessToken, user.email, user.sub);
        setTenants(response.data || []);
      } catch (error) {
        console.error('LeaseUploader - Error fetching tenants for portfolio:', error);
      }
    }
  };


  // Handle tenant change
  const handleTenantChange = async (event) => {
    const tenant = event.target.value;
    setSelectedTenant(tenant);
    setManualTenant('');  // Reset manual entry if a tenant is selected
    setSelectedLease('');
    setLeases([]);

    if (tenant) {
        try {
            const accessToken = await getAccessTokenSilently();
            const portfolio = selectedPortfolio || manualPortfolio;  // Use either selected or manually entered portfolio
            const asset = selectedAsset || manualAsset;  // Use either selected or manually entered asset

            if (portfolio && asset && tenant) {  // Ensure tenant is not empty
                const response = await getLeasesByTenant(accessToken, user.email, user.sub, portfolio, asset, tenant);
                setLeases(response.data || []);
            } else {
                console.error('LeaseUploader - Missing portfolio, asset, or tenant for getLeasesByTenant API call');
            }
        } catch (error) {
            console.error('LeaseUploader - Error fetching leases for tenant:', error);
        }
    }
  };


  // Handle manual tenant entry
  const handleManualTenantChange = (event) => {
    const tenant = event.target.value;
    setManualTenant(tenant);
    setSelectedTenant('');  // Reset selected tenant if manually entering a tenant
    setSelectedLease('');
    setLeases([]);
  };

  const handleLeaseChange = (event) => {
    setSelectedLease(event.target.value);
  };

  const handleCloseModal = () => {
    setShowModal(false); // Close the modal completely
    setStep(0); // Reset to step 0 (for future reuse)
    setShowIncorrectMessage(false);
  };

  const isPDF = async (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.onload = async (event) => {
        const typedArray = new Uint8Array(event.target.result);
        try {
          const pdf = await pdfjsLib.getDocument({ data: typedArray }).promise;
          resolve(pdf.numPages > 0);
        } catch (error) {
          reject(new Error('Not a PDF'));
        }
      };
      fileReader.onerror = (error) => {
        reject(new Error('Could not read the file'));
      };
      fileReader.readAsArrayBuffer(file);
    });
  };



  const handleFeedbackSubmit = async (isCorrect) => {
    // Set loading state to true for both cases
    setIsLoading(true);
  
    try {
      const accessToken = await getAccessTokenSilently();
      const csrfToken = sessionStorage.getItem("csrf_token");
      const response = await postExtractionFeedback(accessToken, user.sub, csrfToken, user.email, isCorrect, timestampFile);
  
      if (response) {
        if (isCorrect) {
          // Move to the review step after extraction if correct
          setStep(3);
        } else {
          // Show apology message if incorrect
          setShowIncorrectMessage(true);
        }
      } else {
        alert("There was an issue processing your feedback. Please try again.");
      }
    } catch (error) {
      console.error('LeaseUploader - Error sending extraction feedback:', error);
      alert("Error occurred during feedback submission. Please try again.");
    } finally {
      // Stop loading spinner in both cases
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (step === 2) {
      console.log('Extracted Data:', extractedData);      
      setTimestampFile(extractedData.timestamp_file);
    }
  }, [step, extractedData]);
  
  
  

  const handleSubmit = async (e) => {
      setIsLoading(true);

      e.preventDefault();
      const file = fileInputRef.current?.files[0];
      if (!file) {
        alert("Please select a file before uploading.");
        setIsLoading(false);  // Reset loading state
        return;
      }
      const MAX_FILE_SIZE = 16 * 1024 * 1024; // 16MB
      if (file.size > MAX_FILE_SIZE) {
        alert('File size exceeds 16MB. Please upload a smaller file.');
        setIsLoading(false);  // Reset loading state
        return;
      }

      try {
          const isFilePDF = await isPDF(file);
          if (!isFilePDF) {
              alert('Please upload a valid PDF file.');
              setIsLoading(false);  // Reset loading state
              return;
          }

          const formData = new FormData();

          // Assign values from selected options or manual inputs
          const portfolio = selectedPortfolio || manualPortfolio;
          const asset = selectedAsset || manualAsset;
          const property = selectedProperty || '';  // Optional property
          const tenant = selectedTenant || manualTenant;
          const lease = selectedLease;

          formData.append('file', file);
          formData.append('asset', asset);
          formData.append('property', property);
          formData.append('lease', lease);
          formData.append('portfolio', portfolio);
          formData.append('tenant', tenant);

          const accessToken = await getAccessTokenSilently();
          //second way to get csrf token = Option B: Grab the token from sessionStorage right before each API call
          const csrfToken = sessionStorage.getItem("csrf_token");
          if (!csrfToken) {
              alert('An error occurred: Try reloading the page.');
              setIsLoading(false);  // Reset loading state
              return;
          }
          const user_id = user.sub;

          const response = await postLeasePDF(accessToken, user_id, formData, csrfToken, user.email, isCommercialUser);

          if (response && response.success) {
            setStep(2); // Move to the review step after extraction
            setExtractedData(response.data);
            setTimestampFile(response.timestamp_file);
        } else {
            // Handling different error messages based on the backend response
            switch (response.message) {
                case 'File has already been uploaded':
                    alert('This file has already been uploaded.');
                    break;
                case 'All questions for this lease have already been answered.':
                    alert('All questions for this lease have already been answered. No need to upload again.');
                    break;
                default:
                    alert(response.message || 'An error occurred: Try reloading the page. If that does not work, please email the lease to info@crelytica.com to be processed by one of our specialists');
                    break;
            }
        }
    } catch (err) {
        let errorMessage = 'An error occurred: Try reloading the page. If that does not work, please email the lease to info@crelytica.com to be processed by one of our specialists';
        if (err && err.message) {
            errorMessage = `${err.message}`;
        }
        alert(errorMessage);
        setIsLoading(false);
    } finally {
        if (fileInputRef.current) {
            fileInputRef.current.value = "";
        }
        setUploadKey(prevKey => prevKey + 1);
        setIsLoading(false);
    }
  };
        

  if (!user) {
    return (
      <div className="content-layout">
        <h1 id="page-title" className="content__title">
          Welcome
        </h1>
        <h2>Sign up or log in to unlock the data in your lease documents</h2>
      </div>
    );
  }

  if (!user.email_verified) {
    return (
      <div className="content-layout">
        <h1 id="page-title" className="content__title">
          Required: verify your email address
        </h1>
        <h2>Please log into the email account you registered with to verify your email address (search 'CRELYTICA' - check in junk if required)</h2>
      </div>
    );
  }
  
  const baseUrl = process.env.REACT_APP_URL;
  
  return (
    <div className="content-layout">
      <h1 id="page-title" className="content__title">Lease AI Extraction</h1>
      <h2>Unlock the data in your lease documents</h2>

      {isLoading ? (
        <div className="loader-wrapper">
          <PageLoader />
          <p className="loading-text">PLEASE DO NOT REFRESH THE PAGE!</p>
          <p className="loading-text">We are running some processes on your PDF for lease extraction with AI.</p>
          <p className="loading-text">Please wait for a minute or two; the larger the document, the longer it takes.</p>
        </div>
      ) : (
        <>
          {/* Step 0: Ask the user if they are commercial or non-commercial */}
          {step === 0 && (

            // Ask if the user is commercial or non-commercial
            <div className="user-type-selection">
              <p></p>
              <div className="user-type-buttons">
                <button className="user-type-button" onClick={() => handleUserTypeSelection(true)}>
                  <img src={`${baseUrl}/assets/Corporate.svg`} alt="Corporate User" className="user-type-icon" />
                  <span>Commercial User</span>
                </button>
                <button className="user-type-button" onClick={() => handleUserTypeSelection(false)}>
                  <img src={`${baseUrl}/assets/Non-Corporate.svg`} alt="Non-Corporate User" className="user-type-icon" />
                  <span>Non-Commercial User</span>
                </button>
              </div>
            </div>
          )}

          {/* Step 1, 2, 3 will be shown inside a modal */}
          {showModal && (
            <div className="modal enhanced-modal">
              <div className="modal-content enhanced-modal-content">
                {/* Close Modal Button */}
                <span className="modal-close" onClick={handleCloseModal}>&times;</span>

                {/* Step 1: Form for uploading lease */}
                {step === 1 && (
                  <div className="modal enhanced-modal">
                    <div className="modal-content enhanced-modal-content">
                    <span className="modal-close" onClick={handleCloseModal}>&times;</span>
                      <h3>{isCommercialUser ? "Select Portfolio, Asset, Property, Tenant, and Lease" : "Select Lease for Upload"}</h3>

                      {isCommercialUser && (
                        <>
                          {/* Commercial User Form */}
                          <div className="modal-section">
                            <div className="label-and-definition">
                              <label htmlFor="portfolio-select">Portfolio</label>
                              <span className="definition-text-upload">The name you give to a group of assets or properties.</span>
                            </div>
                            <div className="input-group">
                              <select id="portfolio-select" value={selectedPortfolio} onChange={handlePortfolioChange}>
                                <option value="">Select Portfolio</option>
                                {portfolios.map((portfolio, index) => (
                                  <option key={index} value={portfolio}>{portfolio}</option>
                                ))}
                              </select>
                              <p className="or-text">or</p>
                              <input
                                type="text"
                                value={manualPortfolio}
                                onChange={handleManualPortfolioChange}
                                placeholder="Type the Portfolio Name"
                                maxLength="150"
                                className={manualPortfolio.length > 150 ? "input-error" : ""}
                              />
                              {manualPortfolio.length > 150 && <p className="error-text">Portfolio name cannot exceed 150 characters.</p>}
                            </div>
                          </div>

                          {/* Show Asset once Portfolio is selected */}
                          {(selectedPortfolio || manualPortfolio) && (
                            <div className="modal-section">
                              <div className="label-and-definition">
                                <label htmlFor="asset-select">Asset</label>
                                <span className="definition-text-upload">The name you give to a property or a group of properties.</span>
                              </div>              
                              <div className="input-group">
                                <select id="asset-select" value={selectedAsset} onChange={handleAssetChange}>
                                  <option value="">Select Asset</option>
                                  {assets.map((asset, index) => (
                                    <option key={index} value={asset}>{asset}</option>
                                  ))}
                                </select>

                                <p className="or-text">or</p>
                                <input
                                  type="text"
                                  value={manualAsset}
                                  onChange={handleManualAssetChange}
                                  placeholder="Type the Asset's Name"
                                  maxLength="255"
                                  className={manualAsset.length > 255 ? "input-error" : ""}
                                />
                                {manualAsset.length > 255 && <p className="error-text">Asset name cannot exceed 255 characters.</p>}
                              </div>
                            </div>
                          )}

                          {/* Show Property once Asset is selected */}
                          {(selectedAsset || manualAsset) && (
                            <div className="modal-section">
                              <div className="label-and-definition">
                                <label htmlFor="property-select">Property (Optional)</label>
                                <span className="definition-text-upload">The property's address</span>
                              </div>
                              <div className="input-group">
                                <select
                                  id="property-select"
                                  value={selectedProperty}
                                  onChange={handlePropertyChange}>                                
                                  <option value="">Select Property (Optional)</option>
                                  {properties.map((property, index) => (
                                    <option key={index} value={property}>{property}</option>
                                  ))}
                                </select>
                              </div>
                            </div>
                          )}

                          {/* Show Tenant once Property is selected */}
                          {(selectedProperty || selectedAsset || manualAsset) && (
                            <div className="modal-section">
                              <div className="label-and-definition">
                                <label htmlFor="tenant-select">Tenant Trading Name</label>
                                <span className="definition-text-upload">The name you know the tenant as</span>
                              </div>
                              <div className="input-group">                                
                                  <select id="tenant-select" value={selectedTenant} onChange={handleTenantChange}>
                                    <option value="">Select Tenant</option>
                                    {tenants.map((tenant, index) => (
                                      <option key={index} value={tenant}>{tenant}</option>
                                    ))}
                                  </select>
                                  <p className="or-text">or</p>
                                  <input
                                    type="text"
                                    value={manualTenant}
                                    onChange={handleManualTenantChange}
                                    placeholder="Type the Tenant's Name"
                                    maxLength="150"
                                    className={manualTenant.length > 150 ? "input-error" : ""}
                                  />
                                {manualTenant.length > 255 && <p className="error-text">Tenant name cannot exceed 255 characters.</p>}
                              </div>
                            </div>
                          )}

                          {/* Show Lease once Tenant is selected */}
                          {(selectedTenant || manualTenant) && (
                            <div className="modal-section">
                              <div className="label-and-definition">
                              <label htmlFor="lease-select">Lease (Optional)</label>
                              <span className="definition-text-upload">Combine this upload with a previously uploaded lease - some leases have multiple documents</span>
                              </div>
                        
                              <div className="input-group">
                                <select id="lease-select" value={selectedLease} onChange={handleLeaseChange}>
                                  <option value="">New Lease</option>
                                  {leases.map((lease, index) => (
                                    <option key={index} value={lease}>{lease}</option>
                                  ))}
                                </select>
                        </div>
                      </div>
                    )}       
                  </>
                )}

                      {!isCommercialUser && (
                        <div className="modal-section">
                          <div className="label-and-definition">
                          </div>
                        </div>
                      )}             
                      <div className="modal-buttons-container">
                        <Button className="modal-button-select" onClick={() => fileInputRef.current.click()}>
                          Select Lease
                        </Button>
                        <input
                          key={uploadKey}
                          className="choose_file__button"
                          type="file"
                          name="file"
                          id="pdfFile"
                          ref={fileInputRef}
                          accept="application/pdf"
                          style={{ display: 'none' }}
                          onChange={handleSubmit}
                        />         
                      </div>
                    </div>
                  </div>
                )}

                {/* Step 2: Review extracted data */}
                {step === 2 && extractedData && !showIncorrectMessage && (
                  <div className="modal enhanced-modal">
                    <div className="modal-content enhanced-modal-content">
                      <span className="modal-close" onClick={handleCloseModal}>&times;</span>
                      <h3>Is this extract of your lease's titles and sub-titles roughly (over 75%) correct?</h3>

                      {/* Show loading spinner if processing */}
                      {isLoading ? (
                        <div className="loader-wrapper">
                          <Spinner animation="border" role="status">
                            <span className="visually-hidden">Processing your feedback...</span>
                          </Spinner>
                          <p className="loading-text">PLEASE DO NOT REFRESH THE PAGE!</p>
                          <p className="loading-text">We are running some processes on your pdf for lease extraction with AI</p>
                          <p className="loading-text">Please wait for a minute or two, the larger the document the longer it takes.</p>
                        </div>
                      ) : (
                        <>
                          {/* Two buttons: Correct and Incorrect */}
                          <div className="modal-buttons-container">
                            <Button className="modal-button-correct" onClick={() => handleFeedbackSubmit(true)}>Correct</Button>
                            <Button className="modal-button-incorrect" onClick={() => handleFeedbackSubmit(false)}>Incorrect</Button>
                          </div>

                          <div className="extracted-data-table">
                            <table>
                              <thead>
                                <tr>
                                  <th>Title</th>
                                  <th>Sub-Title</th>
                                  <th>Bullet</th>
                                  <th>Definition</th>
                                </tr>
                              </thead>
                              <tbody>
                                {extractedData && extractedData.data && Object.keys(extractedData.data).length > 0 ? (
                                  Object.entries(extractedData.data).map(([key, value]) => (
                                    <tr key={key}>
                                      <td>{value.title || "N/A"}</td>
                                      <td>{value.sub_title || "N/A"}</td>
                                      <td>{value.bullet || "N/A"}</td>
                                      <td>{value.definition_variable || "N/A"}</td>
                                    </tr>
                                  ))
                                ) : (
                                  <tr>
                                    <td colSpan="4">No data available</td>
                                  </tr>
                                )}
                              </tbody>

                            </table>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                )}

                {/* Incorrect feedback message */}
                {showIncorrectMessage && (
                  <div className="modal enhanced-modal">
                    <div className="modal-content enhanced-modal-content">      
                    <span className="modal-close" onClick={handleCloseModal}>&times;</span>
                      <h3>We're sorry!</h3>
                      <p>
                        It looks like the software did not extract the lease correctly. Our team will manually process your lease as soon as possible, and you will receive the results via email.
                      </p>
                    </div>
                  </div>
                )}

                {/* Step 3: Success message */}
                {step === 3 && (
                  <div className="modal enhanced-modal">
                    <div className="modal-content enhanced-modal-content">                      
                    <span className="modal-close" onClick={handleCloseModal}>&times;</span>
                      <h2>Success!</h2>
                      <h4  className="content_text">Your document has been uploaded successfully</h4>
                      <div className="modal-buttons-container">
                        <Button 
                          className="modal-button-review" 
                          onClick={() => window.location.href = `${baseUrl}/review`}
                        >
                          Review Uploaded Lease
                        </Button>
                      </div>
                      <h4  className="content_text">Or you can upload a new document</h4>

                      {/* New lease upload button */}
                      <div className="modal-buttons-container">
                        <Button className="modal-button-select" onClick={() => fileInputRef.current.click()}>
                          Upload Another Lease
                        </Button>
                      </div>

                      {/* File input for new lease */}
                      <input
                        key={uploadKey} // Ensure the file input resets when uploading again
                        className="choose_file__button"
                        type="file"
                        name="file"
                        ref={fileInputRef}
                        accept="application/pdf"
                        style={{ display: 'none' }} // Hidden file input
                        onChange={handleSubmit} // Reuse handleSubmit for new upload
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default LeaseUploader;