import React, { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { FooterFeedbackSubmission } from "../services/message.service";

export const PageFooter = () => {
  const { getAccessTokenSilently, user } = useAuth0();

  // State to manage the visibility of the feedback modal
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const [submitFeedback, setSubmitFeedback] = useState({});
  const [feedbackCategory, setFeedbackCategory] = useState("");
  const [feedbackDetail, setFeedbackDetail] = useState("");

  // Retrieve CSRF token from sessionStorage
  const csrfToken = sessionStorage.getItem("csrf_token");

  const handleModalSubmit = async (finalFeedback) => {
    const updatedFeedbackCore = { ...submitFeedback, feedback: finalFeedback };

    try {
      const accessToken = await getAccessTokenSilently();
      const user_email = user?.email;
      const user_id = user?.sub;

      if (!csrfToken) {
        console.error("PageFooter: CSRF token not found. Please log in again.");
        return;
      }

      const { data, error } = await FooterFeedbackSubmission(
        accessToken,
        user_id,
        updatedFeedbackCore,
        csrfToken,
        user_email
      );

      if (data) {
        setShowFeedbackModal(false);
        setFeedbackCategory("");
        setFeedbackDetail("");
        setSubmitFeedback({});
      } else if (error) {
        console.error("Error updating feedback:", error.message);
      }
    } catch (err) {
      console.error("Exception updating feedback:", err.message);
    }
  };

  // Creative Commons License HTML as a string
const ccLicenseHTML = {
  __html: `
<p
  xmlns:cc="http://creativecommons.org/ns#"
  xmlns:dct="http://purl.org/dc/terms/"
  class="footer-cc-license"
  style="display: inline-flex; align-items: center; flex-wrap: wrap; text-align: center;"
>
  <!-- LINE 1 -->
  <span style="margin-right: 4px;">CRELYTICA Data Standard</span>
  <span style="margin-right: 4px;">by CRELYTICA Ltd</span>
  <span style="margin-right: 4px;">is licensed</span>
  <!-- Force break after line 1 -->
  <br class="mobile-break" />

  <!-- LINE 2 -->
  <span style="margin-right: 4px;">under Attribution-NonCommercial-NoDerivatives 4.0 International</span>
  <!-- Force break after line 2 -->
  <br class="mobile-break" />

  <!-- LINE 3: The 4 icons -->
  <img
    style="height:22px!important; margin-left:3px; vertical-align:middle;"
    src="https://mirrors.creativecommons.org/presskit/icons/cc.svg?ref=chooser-v1"
  />
  <img
    style="height:22px!important; margin-left:3px; vertical-align:middle;"
    src="https://mirrors.creativecommons.org/presskit/icons/by.svg?ref=chooser-v1"
  />
  <img
    style="height:22px!important; margin-left:3px; vertical-align:middle;"
    src="https://mirrors.creativecommons.org/presskit/icons/nc.svg?ref=chooser-v1"
  />
  <img
    style="height:22px!important; margin-left:3px; vertical-align:middle;"
    src="https://mirrors.creativecommons.org/presskit/icons/nd.svg?ref=chooser-v1"
  />
</p>

  `,
};


  function FeedbackModal({ isOpen, onClose, onSubmit, feedbackCategory, setFeedbackCategory }) {
    const [localFeedbackDetail, setLocalFeedbackDetail] = useState("");

    const handleCategorySelect = (category) => setFeedbackCategory(category);

    const handleDetailChange = (e) => {
      setLocalFeedbackDetail(e.target.value);
    };

    const handleModalSubmit = () => {
      const finalFeedback = `${feedbackCategory} - ${localFeedbackDetail}`;
      onSubmit(finalFeedback);
      onClose();
      setLocalFeedbackDetail("");
    };

    if (!isOpen) return null;

    return (
      <div className="enhanced-modal">
        <div className="enhanced-modal-content">
          <span className="modal-close" onClick={onClose}>&times;</span>
          {!feedbackCategory ? (
            <>
              <h2>Select a Feedback Category</h2>
              <div className="button-group">
                <button onClick={() => handleCategorySelect("I would like the product to")}>
                  I would like the product to do...
                </button>
                <button onClick={() => handleCategorySelect("The product is great")}>
                  The product is great!
                </button>
                <button onClick={() => handleCategorySelect("Complaint")}>
                  I want to make a complaint…
                </button>
                <button onClick={() => handleCategorySelect("Data standards")}>
                  I have a query regarding data standards…
                </button>
                <button onClick={() => handleCategorySelect("Privacy policy")}>
                  I have a query regarding the privacy policy of the website…
                </button>
                <button onClick={() => handleCategorySelect("Terms")}>
                  I have a query regarding the terms of the website…
                </button>
                <button onClick={() => handleCategorySelect("Data protection")}>
                  I have a query regarding data protection…
                </button>
                <button onClick={() => handleCategorySelect("Other")}>Other</button>
              </div>
            </>
          ) : (
            <>
              <h3>Selected Category: {feedbackCategory}</h3>
              <div className="modal-section">
                <label htmlFor="feedback-detail">Detailed Feedback</label>
                <textarea
                  id="feedback-detail"
                  className="modal-textarea"
                  value={localFeedbackDetail}
                  onChange={handleDetailChange}
                  placeholder="Enter detailed feedback"
                ></textarea>
              </div>
              <div className="modal-buttons-container">
                <button className="modal-button-submit" onClick={handleModalSubmit}>
                  Submit Feedback
                </button>
                <button className="modal-button-change" onClick={() => setFeedbackCategory("")}>
                  Change Category
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    );
  }

  return (
    <footer className="page-footer">
      <div className="page-footer-grid">
        <div className="page-footer-grid__info" style={{ gridColumn: "1 / 2" }}>
          <p className="page-footer-message__headline">
            Please share any requests, suggestions, or feedback with us through{" "}
            <button
              onClick={() => setShowFeedbackModal(true)}
              style={{
                background: "none",
                border: "none",
                color: "#007bff",
                cursor: "pointer",
                padding: 0,
              }}
            >
              this portal.
            </button>
          </p>
          <div className="centered-license-content">
            <div dangerouslySetInnerHTML={ccLicenseHTML} />
            <p className="page-footer-message__headline">
              Copyright © 2021-2024 CRELYTICA, or its affiliates. All rights reserved.
            </p>
          </div>
        </div>
      </div>
      <FeedbackModal
        isOpen={showFeedbackModal}
        onClose={() => setShowFeedbackModal(false)}
        onSubmit={handleModalSubmit}
        feedbackCategory={feedbackCategory}
        setFeedbackCategory={setFeedbackCategory}
      />
    </footer>
  );
};
