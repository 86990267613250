import React from "react";
import { MobileMenuToggleButton } from "./mobile-menu-toggle-button";
import { MobileNavBarBrand } from "./mobile-nav-bar-brand";
import { MobileNavBarButtons } from "./mobile-nav-bar-buttons";
import { MobileNavBarTabs } from "./mobile-nav-bar-tabs";

const MobileMenuState = {
  CLOSED: "closed",
  OPEN: "open",
};

const MobileMenuIcon = {
  CLOSE: "☰",
  MENU: "☰",
};

export const MobileNavBar = () => {
  const [mobileMenuState, setMobileMenuState] = React.useState(
    MobileMenuState.CLOSED
  );
  const [mobileMenuIcon, setMobileMenuIcon] = React.useState(
    MobileMenuIcon.MENU
  );

  const isMobileMenuOpen = () => {
    return mobileMenuState === MobileMenuState.OPEN;
  };

  const closeMobileMenu = () => {
    document.body.classList.remove("mobile-scroll-lock");
    setMobileMenuState(MobileMenuState.CLOSED);
    setMobileMenuIcon(MobileMenuIcon.MENU);
  };

  const openMobileMenu = () => {
    document.body.classList.add("mobile-scroll-lock");
    setMobileMenuState(MobileMenuState.OPEN);
    setMobileMenuIcon(MobileMenuIcon.CLOSE);
  };

  const toggleMobileMenu = () => {
    if (isMobileMenuOpen()) {
      closeMobileMenu();
    } else {
      openMobileMenu();
    }
  };

  return (
    <div className="mobile-nav-bar__container">
      <nav className="mobile-nav-bar">
        <div className="mobile-nav-bar__brand-container">
          <MobileNavBarBrand handleClick={closeMobileMenu} />
        </div>
        <div className="hamburger-container">
          <MobileMenuToggleButton
            icon={mobileMenuIcon}
            handleClick={toggleMobileMenu}
          />

      {isMobileMenuOpen() && (
        <div className="mobile-nav-bar__menu">
          {/* A container just for your tabs/menu items */}
          <div className="mobile-nav-bar__menu-content">
            <MobileNavBarTabs handleClick={closeMobileMenu} />
          </div>

          {/* A separate container for the buttons */}
          <MobileNavBarButtons />
        </div>
      )}
      </div>

      </nav>
    </div>
  );
};
