import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";

export const useTokenRefresh = () => {
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();

  useEffect(() => {
    const refreshToken = async () => {
      try {
        if (isAuthenticated) {
          // Fetch the current access token
          const accessToken = await getAccessTokenSilently();
          const { exp } = JSON.parse(atob(accessToken.split('.')[1])); // Decode JWT payload
          const now = Math.floor(Date.now() / 1000);
          const timeUntilExpiry = exp - now;

          if (timeUntilExpiry < 300) { // Refresh if less than 5 minutes remaining
            const refreshedToken = await getAccessTokenSilently();
            console.log("Token refreshed:", refreshedToken);
          }
        }
      } catch (error) {
        console.error("Error refreshing token:", error);
      }
    };

    // Check periodically
    const intervalId = setInterval(refreshToken, 60000); // Check every minute    

    return () => clearInterval(intervalId);
  }, [getAccessTokenSilently, isAuthenticated]);
};
