import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { logOut } from "../../services/message.service";

export const LogoutButton = () => {
  const { logout, getAccessTokenSilently, user } = useAuth0();

  const handleLogout = async () => {
    try {
      const accessToken = await getAccessTokenSilently();
      const csrfToken = sessionStorage.getItem("csrf_token");

      // Call backend logout to clear session and cookies
      const { error } = await logOut(accessToken, user.sub, csrfToken);
      if (error) {
        console.error("LogoutButton: Error during backend logout:", error);
      } else {
        console.log("LogoutButton: Backend logout successful.");
      }

      // Clear CSRF token from sessionStorage
      sessionStorage.removeItem("csrf_token");

      // Call Auth0 logout
      logout({
        logoutParams: {
          returnTo: window.location.origin,
        },
      });
    } catch (err) {
      console.error("LogoutButton: Error during logout:", err.message);
    }
  };

  return (
    <button className="button__logout" onClick={handleLogout}>
      Log Out
    </button>
  );
};