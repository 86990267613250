import React from "react";
import { NavLink } from "react-router-dom";

const baseUrl = process.env.REACT_APP_URL;

export const MobileNavBarBrand = ({ handleClick }) => {
  return (
    <div onClick={handleClick} className="mobile-nav-bar__brand">
      <NavLink to="/">
        <img
          className="mobile-nav-bar__logo"
          src={`${baseUrl}/assets/CRELYTICA_logo.png`}
          alt="CRELYTICA logo"
          width="240"
          height="96"
        />
      </NavLink>
    </div>
  );
};
