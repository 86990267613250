import { useAuth0 } from "@auth0/auth0-react";
import React from "react";

export const LoginButton = () => {
  const { loginWithRedirect } = useAuth0();

  const handleLogin = async () => {
    try {
      // Trigger login and redirect the user to Auth0's hosted login page
      await loginWithRedirect({
        appState: {
          returnTo: "/",
        },
        authorizationParams: {
          prompt: "login",
        },
      });
    } catch (error) {
      console.error("Login button: Login failed:", error);
    }
  };

  return (
    <button className="button__login" onClick={handleLogin}>
      Log In
    </button>
  );
};
